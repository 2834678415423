<template>
  <div>
    <div class="vip-type-list" style="font-size:16px">
      <span>{{this.$route.query.shopName}}-营业收入</span>
      <div style="display:flex;align-items:center;">
        <div class="date-box">
          <div class="box-left" @click="changeSubNum(false)">＜</div>
          <div>{{yearMonthArr[subNum]}}</div>
          <div class="box-right" @click="changeSubNum(true)">＞</div>
        </div>
        <el-button type="success" @click="this.$router.go(-1)">返回</el-button>
      </div>
    </div>
    <main  style="margin:0 20px">
      <div class="vip-type-list" style="font-size:16px">
        <span style="font-size:14px">营业收入汇总：{{totalIncome}}</span>
      </div>
      <div style="padding-top:20px">
        <el-table 
          :data="dataOne" 
          border  
          stripe style="width: 100%"
          :header-cell-style="headerStyle"
          :cell-style="cellStyle"
        >
          <el-table-column prop="dutyStartTime" label="班次开始时间" width="140"/>
          <el-table-column prop="dutyEndTime" label="班次结束时间"  width="140"/>
          <el-table-column prop="cash" label="现金结算" />
          <el-table-column prop="incomeVirtual" label="储值卡结算" />
          <el-table-column prop="bankMoney" label="网银消费" />
          <el-table-column prop="alipayMoney" label="支付宝消费" />
          <el-table-column prop="wechartMoney" label="微信消费" />
          <el-table-column prop="groupMoney" label="团购消费" />
          <el-table-column prop="shezhang" label="今日挂账" />
          <el-table-column prop="kdbScanPay" label="扫码收入" />
          <el-table-column prop="total" label="合计" />
        </el-table>
      </div>
    </main>
    <Print rul='report/monthReportStatistics.json' :add='"&yearMonth="+yearMonthArr[subNum]+"&chainShopId="+this.$route.query.shopId+"&name="+"incomeMonth"' />
  </div>
</template>

<script>
import {ref} from 'vue'
import {postData} from"@a"
import {useRoute} from 'vue-router'
export default {
  setup(){
    const route = useRoute()
    const dataOne = ref([])

    const headerStyle = ()=>{
      let style  = {}
      style.color = '#333333'
      style['font-size'] = '14px'
      style['font-weight'] = '400'
      style['padding-left'] = '20px'
      style['background-color'] = '#D7D7D7'
      return style
    }
    const cellStyle = ()=>{
      let style  = {}
      style.color = '#000'
      style['font-size'] = '14px'
      style['padding-left'] = '20px'
      style['font-weight'] = '500'
      return style
    }
    let newDate = new Date().getMonth()+1
    let newYear = new Date().getFullYear()
    let yearMonthArr = ref([])
    for(let i = 0;i<12;i++){
      if(newDate-i === 0){
        newDate = 13
        newYear -=1
      }
      let data = ''
      if(newDate-i>0){
        data = newYear + '-' + (newDate-i>9?newDate-i:'0'+(newDate-i))
      }else{
        let j = i - newDate
        data = newYear + '-' + ((newDate-j)>9?(newDate-j):'0'+(newDate-j))
      }
      yearMonthArr.value.unshift(data)
    }
    let subNum = ref(yearMonthArr.value.length-1)
    if(route.query.time){
      for(let i = 0;i<yearMonthArr.value.length;i++){
        if(route.query.time === yearMonthArr.value[i]){
          subNum.value = i
          break
        }
      }
    }
    console.log(yearMonthArr.value)
    const changeSubNum = (val)=>{
      console.log(val,yearMonthArr.value,subNum.value)
      if(val && subNum.value<yearMonthArr.value.length-1){
        subNum.value+=1
        infoData()
      }else if(!val && subNum.value>1){
        subNum.value -=1
        infoData()
      }
    }
    let totalIncome = ref(0)
    const infoData = async()=>{
      dataOne.value = []
      try{
        let param = {
          yearMonth:yearMonthArr.value[subNum.value],
          chainShopId:route.query.shopId
        }
        totalIncome.value = 0
        let res = await postData('shop/monthReportStatistics.json',param)
        for(let i = 0;i<res.data.length;i++){
          totalIncome.value += res.data[i].totalIncome
          res.data[i].total =  Number(res.data[i].cash) + Number(res.data[i].incomeVirtual) +Number(res.data[i].bankMoney) +Number(res.data[i].alipayMoney) +Number(res.data[i].wechartMoney) +Number(res.data[i].groupMoney) + Number(res.data[i].shezhang) + Number(res.data[i].kdbScanPay)
        }
        for(let i = 0;i<res.data.length;i++){
          //           <el-table-column prop="cash" label="现金结算" />
          // <el-table-column prop="incomeVirtual" label="储值卡结算" />
          // <el-table-column prop="bankMoney" label="网银消费" />
          // <el-table-column prop="alipayMoney" label="支付宝消费" />
          // <el-table-column prop="wechartMoney" label="微信消费" />
          // <el-table-column prop="groupMoney" label="团购消费" />
          // <el-table-column prop="shezhang" label="今日挂账" />
          // <el-table-column prop="kdbScanPay" label="扫码收入" />
          // <el-table-column prop="total" label="合计" />
          res.data[i].statisticTime = res.data[i].statisticTime.substring(0,10)
          res.data[i].cash = res.data[i].incomeCashMember + res.data[i].incomeCashOther
          res.data[i].total = res.data[i].cash + res.data[i].incomeVirtual + res.data[i].bankMoney + res.data[i].alipayMoney + res.data[i].wechartMoney + res.data[i].groupMoney+ res.data[i].kdbScanPay+ res.data[i].shezhang
        }
        dataOne.value = res.data
      } catch(err) {
        console.log(err)
      }
    }
    infoData()
    return{
      yearMonthArr,
      subNum,
      changeSubNum,
      dataOne,
      headerStyle,cellStyle,
      totalIncome,

    }
  }
}
</script>
<style scoped>
.vip-type-list{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #D9EFFF;
  height: 65px;
  margin-bottom: 10px;
}
.vip-type-list>span{
  display: flex;
  background-color: #D9EFFF;
  height: 65px;
  line-height: 65px;
  font-size: 16px;
  margin-left: 20px;
  font-weight: 600;
}


.date-box{
    width: 274px;
    height: 32px;
    margin-right: 20px;
    background: inherit;
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(228, 228, 228, 1);
    border-radius: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.box-left{
  width: 30px;
  height: 25px;
  margin: 2.5px 0;
  border-right: 1px solid #b4b4b4;
  font-size: 16px;
  line-height: 25px;
  cursor: pointer;
}
.box-right{
  text-align: right;
  width: 30px;
  height: 25px;
  margin: 2.5px 0;
  font-size: 16px;
  line-height: 25px;
  border-left: 1px solid #b4b4b4;
  cursor: pointer;
}
</style>